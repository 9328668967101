import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
} from "swiper/modules";
import { db } from "../config/firebaseConfig";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Footer from "./footer";

export default function Camaras() {

  const location = useLocation()

  const item = location.state.estado

  const [camaras, setCamaras] = React.useState([
    {
      title: 'S',

    }
  ]);

  React.useEffect(() => {
    const collectionRef1 = collection(db, 'productscams');
    const q2 = query(
      collectionRef1,
      where('estado', '==', item),
    );
    const unsubscribe1 = onSnapshot(q2, querySnapshot1 => {
      console.log('Dartos obtenidos');
      setCamaras(
        querySnapshot1.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title,
          portada: doc.data().portada,
          background: doc.data().background
        })),
      );
    });
    return unsubscribe1;
  }, [])

  console.log(camaras)

  const [estado, setEstado] = React.useState('');
  
  React.useEffect(() => {
    if (item == 'Puebla') {
      setEstado('https://turixcam-images.b-cdn.net/Recursos%20WEB/TEXTOS/PUEBLA/bitmap.png')
    } else if (item == 'Hidalgo') {
      setEstado('https://turixcam-images.b-cdn.net/Recursos%20WEB/TEXTOS/HIDALGO/HIDALGO.png')
    } else if (item == 'Veracruz') {
      setEstado('https://turixcam-images.b-cdn.net/Recursos%20WEB/TEXTOS/VERACRUZ/VZ.png')
    }
  }, [item])



  console.log(item) // Outputs the state object with the camera's name

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <div className="img_camaras"></div>

      
        <img
          style={{
            height: 150,
            width: 300,
            margin: 50,
            alignSelf: 'center'
          }}
          src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2F03.png?alt=media&token=c3f750b4-9078-410f-8a9b-9effe21331a3"
        />
      <div
        style={{
          alignSelf: "center",
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: "85%",
          justifyContent: "center",
          margin: 10,
          padding: 10,
          borderRadius: "15px",
          marginBottom: 100,
          height: 190,
          backgroundColor: "rgba(0,0,0,0.6)",
        }}
      >
        <img
          style={{
            height: 100,
            width: 450,
            margin: 50,
            alignSelf: 'center',
            marginTop: -40
          }}
          src={estado}
        />
      </div>
      <div
        style={{
          width: 1300,
          alignSelf: "center",
          justifyContent: "center",
          marginTop: -190,
          padding: 10,
          marginBottom: 100,
          height: 650,
        }}
      >
        <Swiper
          // install Swiper modules
          effect={"coverflow"}
          modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={"auto"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          pagination={{ clickable: true }}
        >
          {camaras.map((i) => (
            <SwiperSlide
              style={{
                display: "flex",
                width: 350,
                height: 500,
                borderRadius: 15,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                backgroundImage: `url(${i.background})`,
                backgroundSize: "cover",
              }}
              key={i.id}
              {...i}
            >
              <Link to={'live'} state={{ title: i.title }}>
                <img
                  src={i.portada}
                  style={{ height: 200, width: 900, alignSelf: "center" }}
                />
              </Link>  
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Footer />
    </div>
  )
}
