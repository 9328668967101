import { Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Buscador from "./screens/buscador";
import Login from "./components/login";
import Camaras from "./components/camaras";
import Live from "./components/live";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="home" element={<Home />} />
      <Route path="home/camaras" element={<Camaras />} />
      <Route path="home/camaras/live" element={<Live />} />
      <Route path="home/buscador" element={<Buscador />} />
    </Routes>
  );
}

export default App;
